<template>
    <el-dialog
            title="资源列表详情"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            class="dialogTable"
            :before-close="handleClose">
        <div class="tabsCls">
            <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                             :name="item.value"></el-tab-pane>
            </el-tabs>
        </div>
        <el-form size="small" ref="inputForm" @keyup.enter.native="getDataList(1)" :inline="true" :model="inputForm" label-width="100px">
            <el-form-item label="资源类型" prop="resourceType">
                <el-select style="width: 100%" size="small" v-model="inputForm.resourceType" placeholder="资源类型" clearable>
                    <el-option
                            v-for="item in $dictUtils.getDictList('resource_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="资源名称" prop="resourceNameOrId">
                <el-input v-model.trim="inputForm.resourceNameOrId"
                          placeholder="请输入资源名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1)">查询</el-button>
                <el-button size="small" icon="el-icon-refresh-right" @click="resetting()">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                size="small"
                :row-key="'id'"
                v-loading="loading"
                ref="multipleTable"
                height="300px"
                class="table"
        >
            <el-table-column
                    show-overflow-tooltip
                    prop="resource_name"
                    label="资源名称">
            </el-table-column>
            <el-table-column prop="resource_type" label="资源类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dictUtils.getDictLabel("resource_type", scope.row.resource_type, '-') }}
                </template>
            </el-table-column>

            <el-table-column prop="resource_foshot" show-overflow-tooltip label="资源简称">
            </el-table-column>
            <el-table-column prop="resource_desc" label="资源描述" show-overflow-tooltip/>
            <el-table-column
                    prop="resource_title" width="120"
                    show-overflow-tooltip
                    label="主题标题 "></el-table-column>
            <el-table-column
                    prop="resource_source" width="120"
                    show-overflow-tooltip
                    label="资源来源 ">
            </el-table-column>
            <el-table-column
                    prop="written_time" width="120"
                    show-overflow-tooltip
                    label="成文时间">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称"></el-table-column>
            <el-table-column
                v-if="selectTab == 2"
                prop="thematic_name" width="120"
                show-overflow-tooltip
                label="所属专题库 ">
            </el-table-column>
        </el-table>
        <div class="text_center">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <div class="text_center" style="margin-top: 10px">
            <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "resourceList",
    data() {
        return {
            dialogVisible: false,
            selectTab: '1',
            tabsList: [
                {
                    name: '相同资源',
                    value: '1',
                },
                {
                    name: '不同资源',
                    value: '2',
                },
            ],
            inputForm: {
                resourceType: '',
                resourceNameOrId: '',
            },

            loading: false,
            dataList: [],
            current: 1,
            size: 10,
            total: 0,
            id:null,
            thematicId:null,
        }
    },

    methods: {
        init(id,thematicId) {
            this.id=id
            this.thematicId=thematicId
            this.selectTab = '1'
            this.getDataList(1)
        },

        getDataList(type) {
            if (type) {
                this.current = 1
            }
            this.loading = true
            let api = this.api.original.thematicCompareAlike
            if(this.selectTab === '2'){
                api = this.api.original.thematicCompareImparity
            }
            this.$axios(api, {
                current: this.current,
                size: this.size,
                id:this.id,
                thematicId:this.thematicId,
                ...this.inputForm,
            }, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    this.dialogVisible = true
                    this.$nextTick(()=>{
                        this.$refs.multipleTable.doLayout()
                    })
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })

        },

        resetting() {
            this.$refs.inputForm.resetFields()
            this.getDataList(1)
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList();
        },

        handleClick(){
            this.getDataList(1);
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>
/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}
</style>
