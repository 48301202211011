<template>
    <el-dialog
            title="选择对比专题库"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            class="dialogTable"
            :before-close="handleClose">
        <div class="flex_b_c">
            <div class="zhungName">当前专题库名称：{{ selectData.thematicName }}</div>
            <div class="zhungName">当前专题库关联资源数：{{ selectData.resourceNum }}</div>
        </div>

        <el-table
                :data="dataList"
                size="small"
                :row-key="'id'"
                @selection-change="selectionChangeHandle"
                v-loading="loading"
                ref="multipleTable1"
                height="300px"
                class="table"
        >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="thematicName" label="对比专题库名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceNum" label="对比专题库关联资源数量" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="text_center">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <div class="text_center" style="margin-top: 10px">
            <el-button size="small" @click="handleClose()">关闭</el-button>
            <el-button size="small" type="primary" @click="resourceComparison()" v-no-more-click>资源对比</el-button>
        </div>


        <el-dialog
            title="专题库对比"
            :visible.sync="dialogVisible2"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            class="dialogTable"
            :before-close="handleClose2">
            <div class="flex_b_c">
                <div class="zhungName">当前专题库名称：{{ selectData.thematicName }}</div>
                <div class="zhungName">当前专题库关联资源数：{{ selectData.resourceNum }}</div>
            </div>
            <el-table
                :data="dataList2"
                size="small"
                :row-key="'id'"
                ref="multipleTable"
                height="300px"
                class="table"
            >
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="thematicName" label="对比专题库名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="resourceNum" label="对比专题库关联资源数量" show-overflow-tooltip></el-table-column>
                <el-table-column prop="compareNum" label="相同资源数量" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="seedata(scope.row)">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center" style="margin-top: 10px">
                <el-button size="small" @click="handleClose2()">关闭</el-button>
            </div>
        </el-dialog>

        <resource-list ref="resourceList"></resource-list>
    </el-dialog>
</template>

<script>
import ResourceList from "@/views/modules/dyyg/libraryManagement/resourceList.vue";

export default {
    name: "contrast",
    components: {ResourceList},
    data() {
        return {
            dialogVisible: false,
            selectData: {},

            loading: false,
            dataList: [],
            dataListSelect: [],
            current: 1,
            size: 10,
            total: 0,

            dialogVisible2:false,
            dataList2:[],
            state:'',
        }
    },
    methods: {
        init(row,state) {
            this.state = state
            this.selectData = row
            this.dialogVisible = true
            this.$nextTick(() => {
                this.getDataList(1, 1)
            })
        },

        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage) {
                this.$refs.multipleTable1.clearSelection()
            }
            this.loading = true
            let api = this.api.original.thematiclibraryList
            if(this.state === 1){
                api = this.api.original.thematiclibraryCommonList
            }
            this.$axios(api, {
                current: this.current,
                size: this.size,
                id:this.selectData.id,
                thematicName:"",
            }, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    this.$nextTick(() => {
                        this.$refs.multipleTable1.doLayout()
                    })
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.current--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        handleClose() {
            this.$refs.multipleTable1.clearSelection()
            this.dataList = []
            this.dialogVisible = false
        },

        //资源对比
        resourceComparison(){
            if (this.dataListSelect.length == 0){
                this.$message.error('请先选择要对比的资源')
                return
            }
            let ids = this.dataListSelect.map(item => {
                return item.id
            })
            this.$axios(this.api.original.thematiclibraryCompareList, {
                ids:ids,
                thematicId:this.selectData.id,
            }, 'post').then((res) => {
                if (res.status) {
                    this.dataList2 = res.data
                    this.dialogVisible2 = true
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', '');
        },



        handleClose2(){
            this.dialogVisible2 = false
        },

        //查看
        seedata(row){
            this.$refs.resourceList.init(this.selectData.id,row.id)
        },
    },
}
</script>

<style scoped>

</style>
